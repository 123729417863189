import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
// import store from "@/store";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/logout",
    name: "Logout",
    component: () => import("../views/Logout.vue"),
  },
  {
    path: "/signup",
    name: "SignUp",
    component: () => import("../views/SignUp.vue"),
  },
  {
    path: "/settings",
    name: "SettingsGeneral",
    component: () => import("../views/Settings.vue"),
  },
  {
    path: "/settings/:section",
    name: "Settings",
    component: () => import("../views/Settings.vue"),
  },
  {
    path: "/user",
    redirect: () => {
      return "/user/me";
    },
  },
  {
    path: "/newpost",
    name: "MakePost",
    component: () => import("../views/MakePost.vue"),
  },
  {
    path: "/updatepost/:id/:slug?",
    name: "UpdatePost",
    component: () => import("../views/UpdatePost.vue"),
  },
  {
    path: "/editpost/:id/:slug?",
    name: "EditPost",
    component: () => import("../views/EditPost.vue"),
  },

  {
    path: "/user/:username",
    name: "Profile",
    component: () => import("../views/Profile.vue"),
  },

  {
    path: "/tag/:tag",
    name: "TagView",
    component: () => import("../views/TagView.vue"),
    props: true,
  },
  {
    path: "/thing/:id/:slug?",
    name: "ViewPost",
    component: () => import("../views/ViewPost.vue"),
    props: true,
  },
  {
    path: "/search",
    name: "Search",
    component: () => import("../views/Search.vue"),
  },
  {
    path: "/:user",
    redirect: (to) => {
      return { path: "/user/" + to.params.user };
    },
  },
  {
    path: "/activate/:id?/:token?",
    name: "ActivateAccount",
    component: () => import("../views/ActivateAccount.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});
import store from "@/store";
router.beforeEach((to) => {
  // if (to.name == "SignUp" && !localStorage.getItem("hello")) {
  //   return { name: "Home" };
  // }
  if (["MakePost", "UpdatePost", "EditPost"].includes(to.name)) {
    if (
      store.state.isAuthenticated &&
      !store.state.userData.is_verified_email
    ) {
      return { path: "/activate/reminder" };
    }
  }
  return true;
});

export default router;
