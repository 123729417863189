<template>
  <div
    class="post-teaser"
    :class="[hasImage ? '' : 'no-image', noHover ? 'no-hover' : '']"
    @click="
      if (clickable) {
        goToPostPage();
      }
    ">
    <div v-if="hasImage" class="img-box">
      <img :src="thumbnail" />
    </div>
    <h4 class="post-name">{{ realName }}</h4>
    <div class="pill-box">
      <tag-pill
        class="pill"
        v-for="tag in tags"
        :key="tag.name"
        :name="tag.name"
        :color="tag.color"></tag-pill>
    </div>
    <div class="likes">
      <span class="likes-text"> {{ likes }}</span>
      <heart-icon
        class="small-icon heart-icon"
        :class="[isLiked ? 'liked' : '']"></heart-icon>
    </div>
  </div>
</template>

<script>
import TagPill from "@/components/basic/TagPill.vue";
import HeartIcon from "vue-material-design-icons/Heart.vue";
import { imageMixin } from "@/mixins/imageMixin.js";
export default {
  name: "PostTeaser",
  props: {
    postData: Object,
    clickable: {
      type: Boolean,
      default: true,
    },
    noHover: {
      type: Boolean,
      default: false,
    },
    simplified: {
      type: Boolean,
      default: false,
    },
    thumbnailOverride: {
      type: String,
      default: undefined,
    },
  },
  components: {
    TagPill,
    HeartIcon,
  },
  mixins: [imageMixin],
  data() {
    return {
      thumbnail: this.loadingImageSrc,
    };
  },
  computed: {
    realName() {
      return this.postData.title;
    },
    tags() {
      if (!this.postData.tags) return [];
      return this.postData.tags.slice(0, 2);
    },
    likes() {
      return this.postData.likes > 1000
        ? ((this.postData.likes / 1000) | 0) + "K"
        : this.postData.likes;
    },
    hasImage() {
      if (this.thumbnailOverride) return true;
      return (
        (this.postData.album &&
          this.postData.album.sections.length > 0 &&
          this.postData.album.sections.filter(
            (x) => x.image !== null || x.external_url !== null
          ).length) ||
        this.postData.thumbnail ||
        this.postData.image ||
        this.postData.external_url
      );
    },
    isLiked() {
      return (
        this.$store.state.isAuthenticated &&
        this.$store.state.userData.liked_posts &&
        this.$store.state.userData.liked_posts.includes(this.postData.id)
      );
    },
  },
  methods: {
    goToPostPage() {
      this.$store.commit("setViewPost", this.postData);
      this.$router.push(
        "/thing/" + this.postData.id + "/" + this.postData.slug
      );
    },
  },
  mounted() {
    this.thumbnail = this.loadingImageSrc;
    if (this.postData.album) {
      let index = this.postData.main_image;
      if (
        !this.postData.album.sections[index] ||
        (!this.postData.album.sections[index].image &&
          !this.postData.album.sections[index].external_url)
      ) {
        index = 0;
      }
      if (this.thumbnailOverride) {
        this.thumbnail = this.thumbnailOverride;
      } else if (this.postData.album.sections[index].thumbnail) {
        this.thumbnail = this.postData.album.sections[index].thumbnail;
      } else if (this.postData.album.sections[index].external_url) {
        this.getSrc(this.postData.album.sections[index].external_url).then(
          (url) => {
            this.thumbnail = url;
          }
        );
      }
    } else {
      console.log(this.postData);
      console.log(this.thumbnailOverride);
      console.log(this.thumbnail);
      if (this.thumbnailOverride) {
        this.thumbnail = this.thumbnailOverride;
      } else if (this.postData.thumbnail) {
        this.thumbnail = this.postData.thumbnail;
      } else if (this.postData.external_url) {
        this.getSrc(this.postData.external_url).then((url) => {
          this.thumbnail = url;
        });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.post-teaser {
  background-color: $grey-300;
  margin-bottom: 0.2rem;
  border-radius: $border-radius;
  display: grid;
  grid-template-columns: 2.6rem auto 3rem;
  grid-template-rows: 1.3rem 1.3rem;
  grid-template-areas: "icon title title" "icon tags likes";
  transition: background-color 0.3s;
  &:hover {
    &:not(.no-hover) {
      background-color: $primary-300;
      cursor: pointer;
    }
  }
}
.no-image {
  grid-template-areas: "title title" "tags likes";
  grid-template-columns: auto 3rem;
  grid-template-rows: 1.3rem 1.3rem;
  padding-left: 0.2rem;
}
.img-box {
  border-radius: $border-radius;
  height: 2rem + $navbar-padding;
  width: 2rem + $navbar-padding;
  margin: $navbar-padding;
  grid-area: icon;
  overflow: hidden;
  object-fit: cover;
}
.img-box > * {
  width: 2rem + $navbar-padding;
  height: 2rem + $navbar-padding;
  object-fit: cover;
}
.post-name {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 300;
  line-height: 1.8;
  padding: 0 $navbar-padding;
  grid-area: title;
  margin: 0;
  text-align: left;
  font-size: 0.9rem;
  color: $primary-700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pill-box {
  grid-area: tags;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.2rem;
  overflow: hidden;
}
.pill {
  flex-shrink: 2;
  min-width: 0;
  min-height: 0;
  overflow: hidden;
}
.likes {
  background-color: $grey-500;
  border-radius: $border-radius 0 $border-radius 0;
  margin-top: 0.2rem;
  margin-left: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: right;
  column-gap: 0.3rem;
  padding-right: 0.3rem;
}
.likes-text {
  color: $white;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  text-align: left;
}
.small-icon {
  width: 0.7rem;
  font-size: 0.8rem;
  color: $white;
  &.liked {
    color: $pink;
  }
}
</style>
