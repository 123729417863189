<template>
  <nav class="sidebar">
    <logo class="logo" @click="$router.push('/')"></logo>
    <div v-if="!$store.state.isAuthenticated" class="login-buttons">
      <button class="button button-primary" @click="$router.push('/login')">
        Log In
      </button>
      <button class="button button-dark" @click="goToSignUp">Sign Up</button>
    </div>
    <div
      class="extra-nav-button"
      @click="showMobileSidebar = !showMobileSidebar">
      <menu-icon></menu-icon>
    </div>
    <div
      @click="showMobileSidebar = false"
      class="background"
      :class="showMobileSidebar ? 'show' : ''"></div>
    <div
      @click="onSidebarClick"
      class="everything-else flex-center-column"
      :class="showMobileSidebar ? 'mobile-shown' : ''">
      <compact-avatar v-if="$store.state.isAuthenticated"></compact-avatar>
      <button
        class="button button-info make-post-btn"
        v-if="$store.state.isAuthenticated"
        @click="$router.push('/newpost')">
        Post a thing
      </button>
      <search-bar @clicked="showMobileSidebar = false"></search-bar>
      <posts-container
        name="popular posts"
        :posts="popularPosts"></posts-container>
      <posts-container
        name="latest posts"
        :posts="latestPosts"></posts-container>

      <router-link
        to="/logout"
        v-if="$store.state.isAuthenticated"
        class="button button-danger logout-btn"
        >Log Out</router-link
      >
    </div>
  </nav>
</template>

<script>
import CompactAvatar from "./CompactAvatar.vue";
import SearchBar from "./SearchBar.vue";
import Logo from "./basic/Logo.vue";
import PostsContainer from "@/components/posts/PostsContainer.vue";
import MenuIcon from "vue-material-design-icons/Menu.vue";
import axios from "axios";
export default {
  name: "Navbar",
  components: {
    CompactAvatar,
    SearchBar,
    Logo,
    PostsContainer,
    MenuIcon,
  },
  data() {
    return {
      popularPosts: [],
      latestPosts: [],
      showMobileSidebar: false,
    };
  },
  mounted() {
    axios
      .get("/api/v1/posts/all/", {
        params: { sorting: "popular", public: true },
      })
      .then((res) => {
        res.data.posts.slice(0, 6).forEach((post) => {
          this.popularPosts.push(post);
        });
      });
    axios
      .get("/api/v1/posts/all/", {
        params: { sorting: "latest", public: true },
      })
      .then((res) => {
        res.data.posts.slice(0, 6).forEach((post) => {
          this.latestPosts.push(post);
        });
      });
  },
  methods: {
    onSidebarClick(ev) {
      if (ev.target.id !== "search") {
        this.showMobileSidebar = false;
      }
    },
    goToSignUp() {
      // if (localStorage.getItem("hello")) {
      this.$router.push("/signup");
      // } else {
      //   this.$notify({
      //     title: "Sorry",
      //     body: "Signups arent open yet. Maybe next month.",
      //     type: "danger",
      //   });
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  &:hover {
    cursor: pointer;
  }
}
.sidebar {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: $navbar-width-lg;
  padding: 0 $navbar-padding;
  max-width: $navbar-width-lg;
  height: 100vh;
  border-radius: 0 5px 5px 0;
  margin: 0;
  background-color: $grey-200;
  right: 0;
  padding-top: 0.7rem;
  padding-bottom: 3rem;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
}
.login-buttons {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 0.5rem;
}
.extra-nav-button {
  display: none;
}
.list {
  display: flex;
  flex-direction: column;
  list-style: none;
}
.make-post-btn,
.logout-btn {
  margin: $item-margin;
}
.background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: $black;
  opacity: 0.4;
  display: none;
  z-index: 3;
  &.show {
    display: inline;
  }
}
@media screen and (max-width: $switch-width) {
  .sidebar {
    position: relative;
    height: $navbar-height-sm;
    width: 100%;
    max-width: 100vw;
    padding: 0 1rem;
    top: 0;
    left: 0;
    flex-direction: row;
    overflow: hidden;
    justify-content: space-between;
    align-items: center;
  }
  .login-buttons {
    column-gap: 0.3rem;
    margin-bottom: 0;
    & > * {
      font-size: 1rem;
      padding: $item-margin;
    }
  }
  .list {
    flex-direction: row;
    gap: 5px;
  }
  .make-post-btn {
    margin-top: 1rem;
  }
  .everything-else {
    z-index: 10000;
    background-color: $white;
    height: 100%;
    position: fixed;
    width: $navbar-width-sm;
    right: -70vw;
    display: flex;
    justify-content: flex-start;
    top: 0;
    transition: right 0.3s, display 1s;
    padding-top: 1rem;
    overflow-y: scroll !important;
    -webkit-overflow-scrolling: touch;
    &.mobile-shown {
      right: 0;
      box-shadow: $black 2px 10px 10px;
    }
  }
  .extra-nav-button {
    display: flex;
    font-size: 2.5em;
    align-items: center;
    justify-items: center;
    height: 100%;
    margin-top: -1rem;
  }
  .extra-nav-button > * {
    margin: auto;
    padding: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40%;
  }
}
</style>
