<template>
  <div class="posts-container">
    <div class="top-bar">
      <div class="dropdown-section selection-category">
        <h5 class="category-sorting">
          <span class="selection">{{ styledCategory(categorySelected) }}</span
          >&nbsp;Posts
        </h5>
        <div
          class="dropdown-menu category-dropdown"
          :class="hideCategory ? 'hidden' : ''">
          <h5
            class="dropdown-choice"
            v-for="category in possibleCategories.filter(
              (x) => x !== categorySelected
            )"
            :key="category"
            @click="changeCategory(category)">
            {{ styledCategory(category) }}
          </h5>
        </div>
      </div>
      <div class="dropdown-section selection-time">
        <h5 class="time-sorting">
          <span class="selection">{{ styledTime(timeSelected) }}</span>
          &nbsp;time
        </h5>
        <div
          class="dropdown-menu time-dropdown"
          :class="hideTime ? 'hidden' : ''">
          <h5
            class="dropdown-choice"
            v-for="time in possibleTimes.filter((x) => x !== timeSelected)"
            :key="time"
            @click="changeTime(time)">
            {{ styledTime(time) }}
          </h5>
        </div>
      </div>
      <div class="dropdown-section selection-filter">
        <h5 class="filter-sorting">
          <span class="selection">{{ styledFilter(filterSelected) }}</span>
          &nbsp;filter
        </h5>
        <div
          class="dropdown-menu filter-dropdown"
          :class="hideFilter ? 'hidden' : ''">
          <h5
            class="dropdown-choice"
            v-for="filter in possibleFilters.filter(
              (x) => x !== filterSelected
            )"
            :key="filter"
            @click="changeFilter(filter)">
            {{ styledFilter(filter) }}
          </h5>
        </div>
      </div>
      <div class="spacer"></div>
      <h5 class="view-type">
        view as:
        <list-view-icon
          class="small-icon"
          :class="[isListView ? 'selected' : '']"
          title="list view"
          @click.stop="setListView(true)"></list-view-icon>
        <gallery-view-icon
          class="small-icon"
          :class="[!isListView ? 'selected' : '']"
          title="gallery view"
          @click.stop="setListView(false)"></gallery-view-icon>
      </h5>
    </div>
    <div class="all-posts-list-view" v-if="isListView">
      <post-list-view
        v-for="post in posts"
        :key="post.id"
        :postData="post"></post-list-view>
    </div>
    <div class="all-posts-gallery-view" v-else>
      <post-gallery-view
        v-for="post in posts"
        :key="post.id"
        :postData="post"></post-gallery-view>
    </div>
    <div class="loader" :class="expanded ? 'expanded' : ''" ref="loader">
      <h4 class="loading-text" :class="loading ? 'loading' : ''">
        {{ loading ? "loading more things..." : "..." }}
      </h4>
    </div>
  </div>
</template>

<script>
import PostListView from "@/components/posts/PostListView.vue";
import ListViewIcon from "vue-material-design-icons/FormatListBulleted.vue";
import GalleryViewIcon from "vue-material-design-icons/Grid.vue";
import PostGalleryView from "./PostGalleryView.vue";
export default {
  name: "PostsLargeContainer",
  components: {
    PostListView,
    ListViewIcon,
    GalleryViewIcon,
    PostGalleryView,
  },
  props: {
    posts: Array,
    category: {
      type: String,
      default: "popular",
    },
    time: {
      type: String,
      default: "recent",
    },
    filter: {
      type: String,
      default: "none",
    },
    listView: {
      type: Boolean,
      default: true,
    },
    name: {
      type: String,
      default: "none",
    },
  },
  data() {
    return {
      isListView: true,
      timeSelected: "recent",
      filterSelected: "none",
      categorySelected: "popular",
      possibleCategories: ["popular", "latest", "following", "views", "liked"],
      possibleTimes: ["recent", "latest", "month", "year"],
      possibleFilters: ["none", "c", "i", "p", "s"],
      hideCategory: false,
      hideFilter: false,
      hideTime: false,
      expanded: false,
      loading: false,
      loaderActive: false,
      scrollLoaderAbort: {},
    };
  },
  computed: {},
  mounted() {
    requestAnimationFrame(() => {
      this.setOnScroll();
    });
    this.isListView = this.$props.listView;
    this.categorySelected = this.category;
    this.filterSelected = this.filter;
    this.timeSelected = this.time;
    if (
      this.name !== "none" &&
      localStorage.getItem("isListView_" + this.name) !== null
    ) {
      this.isListView =
        localStorage.getItem("isListView_" + this.name) == "true";
    }
  },
  methods: {
    setListView(bool) {
      if (localStorage.getItem("useBasicCookies") && this.name !== "none") {
        localStorage.setItem("isListView_" + this.name, bool);
      }
      this.isListView = bool;
    },
    styledTime(timeSelected) {
      switch (timeSelected) {
        case "month":
          return "last month";
        case "year":
          return "last year";
        default:
          return timeSelected;
        //all and recent need no change
      }
    },
    styledCategory(categorySelected) {
      switch (categorySelected) {
        case "liked":
          return "most liked";
        case "views":
          return "most viewed";
        default:
          return categorySelected;
        //popular and latest need no change
      }
    },
    styledFilter(filterSelected) {
      switch (filterSelected) {
        case "none":
          return "no";
        case "c":
          return "completed";
        case "p":
          return "planning";
        case "i":
          return "in progress";
        case "s":
          return "stalled";
        default:
          return "none";
      }
    },
    changeTime(time) {
      this.timeSelected = time;
      this.hideTime = true;
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          this.hideTime = false;
        });
      });
      this.emitFilters();
    },
    changeFilter(filter) {
      this.filterSelected = filter;
      this.hideFilter = true;
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          this.hideFilter = false;
        });
      });
      this.emitFilters();
    },
    changeCategory(category) {
      this.categorySelected = category;
      this.hideCategory = true;
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          this.hideCategory = false;
        });
      });
      this.emitFilters();
    },
    emitFilters() {
      this.$emit("changeFilters", {
        time: this.timeSelected,
        sorting: this.categorySelected,
        filter: this.filterSelected,
      });
    },
    setOnScroll() {
      this.scrollLoaderAbort = new AbortController();
      window.addEventListener(
        "scroll",
        () => {
          let loader = this.$refs.loader.getBoundingClientRect();
          if (loader.top < window.innerHeight) {
            this.expanded = true;
            if (this.loaderActive && !this.loading) this.$emit("reachedEnd");
          } else {
            this.expanded = false;
          }
        },
        { signal: this.scrollLoaderAbort.signal }
      );
    },
    setLoading(bool) {
      this.loading = bool;
    },
    setLoadedActive(bool) {
      this.loaderActive = bool;
      this.expanded = false;
    },
  },
  unmounted() {
    this.scrollLoaderAbort.abort();
  },
};
</script>

<style lang="scss" scoped>
.posts-container {
  width: 100%;
  min-height: 100vh;
}
.top-bar {
  padding: $item-margin;
  display: flex;
  flex-direction: row;
}
.dropdown-section > *,
.view-type {
  color: $black;
  font-weight: 300;
  font-size: 1.3rem;
  line-height: 1.5;
  margin: 0 0.5rem;
  text-transform: lowercase;
}
.dropdown-section {
  &:hover {
    .selection {
      color: $primary;
    }
    .selection::after {
      content: " ▲" !important;
    }
  }
}
.selection {
  font-weight: 400;
  cursor: pointer;
  transition: 0.3s;
  &::after {
    content: " ▼";
    position: relative;
    top: -0.1rem;
    font-size: 0.6rem;
  }
}
.dropdown-menu {
  background-color: $white;
  position: absolute;
  z-index: 1;
  padding: 0.3rem;
  border-radius: 0 0 $border-radius $border-radius;
  &.hidden {
    display: none !important;
  }
}
.dropdown-choice {
  font-weight: 300;
  font-size: 1rem;
  cursor: pointer;
  margin: 0.1rem;
  transition: color 0.3s;
  &:hover {
    color: $primary-500;
  }
}
.category-dropdown {
  display: none;
}
.selection-category {
  &:hover,
  &:active {
    .category-dropdown {
      display: block;
    }
  }
}
.time-dropdown {
  display: none;
}
.selection-time {
  &:hover,
  &:active {
    .time-dropdown {
      display: block;
    }
  }
}
.filter-dropdown {
  display: none;
}
.selection-filter {
  &:hover,
  &:active {
    .filter-dropdown {
      display: block;
    }
  }
}
.view-type {
  justify-self: end;
}
.small-icon {
  font-size: 1.2rem;
  margin: 0 0.2rem;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    color: $primary-200;
  }
}
.selected {
  color: $primary-300;
}
.all-posts-gallery-view {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
}
.all-posts-list-view {
  display: flex;
  flex-direction: column;
}
.loader {
  height: 2rem;
  transition: 0.5s;
  display: flex;
  justify-content: center;
  &.expanded {
    height: 10rem;
  }
}
.loading-text {
  color: $black;
  font-weight: 200;
  font-size: 2rem;
  transition: 0.5s;
  &.loading {
    color: $primary-700;
  }
}
@media screen and (max-width: $switch-width) {
  h5 {
    font-size: 0.9rem !important;
    margin: 0 0.1rem !important;
  }
  .small-icon {
    font-size: 1rem;
  }
  .selection::after {
    font-size: 0.6rem;
    top: 0;
  }
  .top-bar {
    justify-content: space-evenly;
  }
  .spacer {
    display: none;
  }
}
</style>
