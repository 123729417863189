const state = () => ({
  notifications: [],
});

const mutations = {
  add(state, notification) {
    state.notifications.push(notification);
  },
  remove(state, id) {
    state.notifications.splice(
      state.notifications.map((x) => x.id).indexOf(id),
      1
    );
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
