import axios from "axios";
import store from "../store";
export const socialsMixin = {
  methods: {
    like(id) {
      if (!store.state.isAuthenticated) return;
      axios.post("api/v1/posts/like/" + id + "/").then((res) => {
        if (res.status != 200) {
          return;
        }
        store.state.userData.liked_posts.push(id);
      });
    },
    dislike(id) {
      if (!store.state.isAuthenticated) return;
      axios.post("api/v1/posts/dislike/" + id + "/").then((res) => {
        if (res.status != 200) {
          return;
        }
        store.state.userData.liked_posts.splice(
          store.state.userData.liked_posts.indexOf(id),
          1
        );
      });
    },
    follow(id) {
      axios.post("api/v1/posts/follow/" + id + "/").then((res) => {
        if (res.status != 200) {
          return;
        }
        store.state.userData.following_posts.push(id);
      });
    },
    unfollow(id) {
      axios.post("api/v1/posts/unfollow/" + id + "/").then((res) => {
        if (res.status != 200) {
          return;
        }
        store.state.userData.following_posts.splice(
          store.state.userData.following_posts.indexOf(id),
          1
        );
      });
    },
    likeProfile(userID) {
      axios.post("api/v1/users/like/" + userID + "/").then((res) => {
        if (res.status != 200) {
          return;
        }
        if (store.state.isAuthenticated) {
          store.state.userData.liked_profiles.push(userID);
        } else {
          let profiles = JSON.parse(
            localStorage.getItem("offline_liked_profiles") || "{}"
          );
          profiles[userID] = "liked";
          localStorage.setItem(
            "offline_liked_profiles",
            JSON.stringify(profiles)
          );
        }
      });
    },
    dislikeProfile(userID) {
      axios.post("api/v1/users/dislike/" + userID + "/").then((res) => {
        if (res.status != 200) {
          return;
        }
        if (store.state.isAuthenticated) {
          store.state.userData.liked_profiles.splice(
            store.state.userData.liked_profiles.indexOf(userID),
            1
          );
        } else {
          let profiles = JSON.parse(
            localStorage.getItem("offline_liked_profiles") || "{}"
          );
          profiles[userID] = undefined;
          localStorage.setItem(
            "offline_liked_profiles",
            JSON.stringify(profiles)
          );
        }
      });
    },
    followProfile(userID) {
      if (!store.state.isAuthenticated) return;
      axios.post("api/v1/users/follow/" + userID + "/").then((res) => {
        if (res.status != 200) {
          return;
        }
        store.state.userData.followed_profiles.push(userID);
      });
    },
    unfollowProfile(userID) {
      if (!store.state.isAuthenticated) return;
      axios.post("api/v1/users/unfollow/" + userID + "/").then((res) => {
        if (res.status != 200) {
          return;
        }
        store.state.userData.followed_profiles.splice(
          store.state.userData.followed_profiles.indexOf(userID),
          1
        );
      });
    },
  },
};
