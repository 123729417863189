<template>
  <div class="icon-input" @click="onClick">
    <search-icon id="search-icon" @click.prevent="goToSearch"></search-icon>
    <input
      type="text"
      class="input-field"
      id="search"
      v-model="searchQuery"
      placeholder="search for a thing"
      @keyup.enter="goToSearch" />
    <button class="search-button button" @click="goToSearch">go</button>
  </div>
</template>

<script>
import SearchIcon from "vue-material-design-icons/Magnify.vue";
export default {
  name: "SearchBar",
  components: { SearchIcon },
  data() {
    return {
      searchQuery: "",
    };
  },
  methods: {
    goToSearch() {
      if (this.searchQuery === "") {
        return;
      }
      this.$emit("clicked");
      this.$router.push({
        name: "Search",
        query: {
          query: this.searchQuery,
        },
      });
    },
    onClick(ev) {
      console.log(ev);
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-input {
  position: relative;
  display: flex;
  border-radius: $border-radius;
  height: fit-contents;
  margin: $item-margin;
}
#search-icon {
  font-size: 1.3rem;
  position: absolute;
  margin-left: 0.4rem;
  margin-top: -0.3rem;
  color: $grey-600;
}
.search-button {
  opacity: 0;
  position: absolute;
  right: 0rem;
  padding: $item-margin;
}
.input-field {
  height: 2.5rem;
  width: 100%;
  padding-left: 2.3rem;
  border: none;
  border-radius: $border-radius;
  font-weight: 900;
  font-family: "Source Sans Pro", sans-serif;
  background-color: white;
  color: $grey-600;
  outline: $grey-200 solid;
  &:hover {
    outline: $primary solid 1px;
  }
  &:focus {
    outline: $primary solid 2px;
    color: $primary;
    &::placeholder {
      color: $white;
    }
    + .search-button {
      opacity: 1;
      color: $primary;
      cursor: pointer;
    }
  }
}
</style>
