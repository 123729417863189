<template>
  <div class="logo-small" id="logo">
    <img src="../../assets/svg/main_logo.svg" />
    <h5>
      <span class="odd-word">i</span><span class="even-word">can</span
      ><span class="odd-word">do</span><span class="even-word">a</span
      ><span class="odd-word">thing</span>
    </h5>
  </div>
</template>

<script>
export default {
  name: "Logo",
};
</script>

<style lang="scss" scoped>
img {
  width: 2rem;
}
.logo-small {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  height: 3rem;
  margin-bottom: 1rem;
  border-radius: $border-radius;
}
h5 {
  font-size: 1.4rem;
  font-family: sans-serif;
  margin-left: 0.5rem;
  user-select: none;
}
.even-word {
  color: $primary;
}
@media screen and (max-width: $switch-width) {
  .logo-small {
    margin: 0;
  }
}
</style>
