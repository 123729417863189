<template>
  <div class="tag-pill" :style="realColor">
    <span class="tag-name" :style="realTextColor">{{ realName }}</span>
  </div>
</template>

<script>
export default {
  name: "TagPill",
  props: {
    name: {
      type: String,
      default: "no name",
    },
    color: {
      type: Number,
      default: null,
    },
    fontSize: {
      type: Number,
      default: 0.6,
    },
  },
  data() {
    return {
      colorVal: null,
    };
  },
  computed: {
    realColor() {
      return (
        "background-color: hsl(" +
        this.colorVal +
        ",70%,70%);  border-color: hsla(" +
        this.colorVal +
        ",100%,30%,1);"
      );
    },
    realName() {
      return this.$props.name;
    },
    realTextColor() {
      return (
        "color: hsl(" +
        this.colorVal +
        ",100%,30%); font-size:" +
        this.$props.fontSize +
        "rem;"
      );
    },
  },
  methods: {
    getColorVal() {
      let col = this.$props.color;
      if (col === null) {
        col = (Math.random() * 360) | 0;
      } else {
        col = this.$props.color;
      }
      this.colorVal = col;
    },
  },
  mounted() {
    this.getColorVal();
  },
};
</script>

<style lang="scss" scoped>
.tag-pill {
  border-radius: 1rem;
  border: solid $dark 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem 0 0.5rem;
  height: fit-content;
}
.tag-name {
  font-family: "Source Sans Pro", sans-serif;
  margin: 0;
  padding: 0;
}
</style>
