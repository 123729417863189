<template>
  <Transition name="slide">
    <div class="notification" v-if="showing" :class="colour">
      <div class="notification-content" @click="handleClick">
        <div class="notification-title">
          <h5 class="notification-title-text">{{ notificationData.title }}</h5>
        </div>
        <div class="notification-body">
          <div class="notification-body-text">
            <p class="no-margin">{{ notificationData.body }}</p>
          </div>
          <small class="notification-body-time">{{
            notificationData.dateTime
          }}</small>
        </div>
      </div>
      <div class="notification-close-action">
        <span class="notification-close" @click="removeNotification"
          >&times;</span
        >
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  computed: {
    hasIcon() {
      return this.validIcons.includes(this.data.icon);
    },
    hasAvatar() {
      return this.data.icon !== "" && !this.hasIcon;
    },
    colour() {
      if (this.validColours.includes(this.notificationData.type)) {
        return this.notificationData.type;
      } else {
        return "primary";
      }
    },
  },
  props: {
    iconClick: {
      type: Function,
    },
    onClick: {
      type: Function,
    },
    notificationData: {
      type: Object,
      default: () => {
        return {
          title: "Notification",
          body: "You have a notification!",
          type: "primary",
          icon: "",
        };
      },
    },
    hasProgressBar: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleClick() {
      if (this.onClick) {
        this.onClick();
      } else {
        this.removeNotification();
      }
    },
    handleIconClick() {
      if (this.iconClick) {
        this.iconClick();
      }
    },
    removeNotification() {
      clearTimeout(this.removeTimeout);
      this.showing = false;
      setTimeout(() => {
        this.$store.commit("notifications/remove", this.notificationData.id);
      }, 2000);
    },
  },
  data() {
    return {
      validColours: [
        "primary",
        "secondary",
        "danger",
        "warning",
        "success",
        "info",
      ],
      showing: false,
      removeTimeout: 0,
      duration: 2000,
    };
  },
  mounted() {
    if (this.notificationData.duration !== undefined) {
      this.duration = this.notificationData.duration;
    }
    this.showing = true;
    this.removeTimeout = setTimeout(() => {
      this.removeNotification();
    }, this.duration);
  },
};
</script>

<style lang="scss" scoped>
.notification {
  z-index: 1000000000;
  position: relative;
  width: 300px;
  height: 100px;
  background-color: $white;
  border-radius: $border-radius;
  margin: 5px;
  display: flex;
  box-shadow: rgba($color: #222222, $alpha: 0.2) 0 0 2px 2px,
    rgba($color: #222222, $alpha: 0.1) 0 0 7px 3px;
  &.primary {
    border-left: $primary-400 solid 3px;
  }
  &.danger {
    border-left: $danger-400 solid 3px;
  }
  &.warning {
    border-left: $warning-400 solid 3px;
  }
  &.secondary {
    border-left: $grey-400 solid 3px;
  }
  &.info {
    border-left: $info-400 solid 3px;
  }
  &.success {
    border-left: $green solid 3px;
  }
}
.notification-icon {
  border: blue solid 2px;
}
.notification-title-text {
  font-size: 1.3rem;
  margin: $item-margin;
  font-weight: 600;
}
.avatar-icon {
  border: blue solid 1px;
  height: inherit;
  width: 100px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.notification-content {
  display: grid;
  grid-template-rows: 30% 70%;
  margin-left: $item-margin;
}
.notification-close-action {
  position: absolute;
  top: -5px;
  right: 10px;
  margin: 0;
  padding: 0;
  font-size: 35px;
  user-select: none;
  &:hover {
    cursor: pointer;
  }
}

.slide-enter-active {
  transition: all 0.3s ease-out;
}

.slide-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(300px);
  opacity: 0;
}

@keyframes popin {
  0% {
    transform: translateX(400px);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
