<template>
  <div class="posts-container">
    <p id="title" class="words">{{ name }}:</p>
    <post-teaser
      v-for="post in posts"
      :key="post.id"
      :postData="post"></post-teaser>
    <p id="more" class="words" @click="$router.push('/')">more →</p>
  </div>
</template>

<script>
import PostTeaser from "@/components/posts/PostTeaser.vue";
export default {
  name: "PostBox",
  components: {
    PostTeaser,
  },
  props: {
    posts: Array,
    name: String,
  },
};
</script>

<style lang="scss" scoped>
.posts-container {
  background-color: white;
  margin: $item-margin;
  border-radius: $border-radius;
  padding: 0 $navbar-padding;
}
.words {
  font-family: "Source Sans Pro", sans-serif;
  padding-left: $navbar-padding;
  line-height: 0;
  font-weight: 300;
  letter-spacing: 1.5px;
}
#title {
  color: $primary;
  text-align: left;
}
#more {
  text-align: right;
  color: $grey-400;
  transition: 1s;
  &:hover {
    cursor: pointer;
    color: $primary-400;
  }
}
</style>
