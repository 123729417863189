<template>
  <div class="page-home wrapper">
    <div class="content">
      <router-view></router-view>
      <Footer></Footer>
    </div>
    <div class="undersidebar"></div>
    <navbar class="navbar"></navbar>
    <LoadingIcon :show="$store.state.isLoading"></LoadingIcon>
    <NotificationsContainer></NotificationsContainer>
  </div>
</template>

<script>
import LoadingIcon from "@/components/LoadingIcon.vue";
import NotificationsContainer from "@/components/notifications/NotificationsContainer.vue";
import Navbar from "@/components/Navbar.vue";
import Footer from "./components/Footer.vue";
export default {
  components: {
    LoadingIcon,
    NotificationsContainer,
    Navbar,
    Footer,
  },
  beforeCreate() {
    this.$store.dispatch("initialize");
    localStorage.setItem("useBasicCookies", true);
  },
  data() {
    return {
      showLoading: false,
    };
  },
  methods: {
    getUsername() {
      if (this.$store.state.isAuthenticated) {
        return this.$store.state.userData.username;
      }
      return "me";
    },
  },
  created() {
    // setInterval(() => {
    //   console.log("hi");
    //   let type = [
    //     "primary",
    //     "secondary",
    //     "danger",
    //     "warning",
    //     "success",
    //     "info",
    //   ][(Math.random() * 6) | 0];
    //   this.$notify({
    //     title: type,
    //     body: new Date(),
    //     type: type,
    //   });
    // }, 2000);
  },
};
</script>

<style lang="scss">
body {
  padding: 0;
  margin: 0;
}
.wrapper {
  display: grid;
  width: 100%;
  height: 100vh;
  padding: 0;
  margin: 0;
  grid-template-columns: $content-width-lg $navbar-padding $navbar-width-lg;
  grid-template-rows: 100%;
  grid-template-areas: "content space navbar";
}
.undersidebar {
  width: $navbar-width-lg;
  right: 0;
  min-height: 100vh;
  visibility: hidden;
  grid-area: navbar;
}
.content {
  min-height: 100%;
  width: $content-width-lg;
  display: flex;
  grid-area: content;
  flex-direction: column;
}
@media screen and (max-width: $switch-width) {
  .undersidebar {
    display: none;
    grid-area: none;
  }
  .wrapper {
    grid-template-columns: 100%;
    grid-template-rows: $navbar-height-sm auto;
    grid-template-areas: "navbar" "content";
  }
  .content {
    width: 100%;
  }
  .navbar {
    grid-area: navbar;
    display: sticky;
  }
}
</style>
