<template>
  <div class="page-home">
    <posts-container
      ref="container"
      :posts="posts"
      :listView="false"
      name="home"
      @changeFilters="onChangeFilter"
      @reachedEnd="onReachedEnd"></posts-container>
  </div>
</template>

<script>
import axios from "axios";
import PostsContainer from "../components/posts/PostsLargeContainer.vue";
export default {
  name: "Home",
  components: {
    PostsContainer,
  },
  data() {
    return {
      lastPostRecieved: 0,
      sorting: "popular",
      time: "recent",
      filter: "none",
      posts: [],
    };
  },
  methods: {
    async getNextFewPosts() {
      return new Promise((resolve) => {
        this.$store.commit("setLoading", true);
        axios
          .get("/api/v1/posts/all/", {
            params: {
              from: this.lastPostRecieved,
              time: this.time,
              filter: this.filter,
              sorting: this.sorting,
            },
          })
          .then((res) => {
            this.$store.commit("setLoading", false);
            console.log(res);
            if (res.data.is_end) {
              this.$refs.container.setLoadedActive(false);
            } else {
              this.$refs.container.setLoadedActive(true);
            }
            res.data.posts.forEach((post) => {
              this.posts.push(post);
              this.lastPostRecieved++;
            });
            resolve();
          })
          .catch((err) => {
            if (err.response.status === 500) {
              this.$notify({
                title: "oh no",
                body: "There's an issue with out servers, please try again later.",
                type: "danger",
                duration: 5000,
              });
            } else {
              this.$notify({
                title: "hmm",
                body: "There's an issue getting the posts. Check your internet perhaps.",
                type: "danger",
                duration: 5000,
              });
            }
          });
      });
    },
    onChangeFilter(data) {
      this.sorting = data.sorting || "popular";
      this.time = data.time || "recent";
      this.filter = data.filter || "none";
      this.lastPostRecieved = 0;
      this.posts.splice(0);
      this.getNextFewPosts();
    },
    onReachedEnd() {
      console.log("reached the end, gonna load");
      this.$refs.container.setLoading(true);
      this.getNextFewPosts().then(() => {
        this.$refs.container.setLoading(false);
      });
    },
  },
  mounted() {
    this.$store.dispatch("ping");
    this.getNextFewPosts();
    document.title = "icandoathing";
  },
};
</script>

<style lang="scss" scoped>
.page-home {
  width: 100%;
}
</style>
