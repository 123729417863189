<template>
  <div class="main-footer">
    <div class="col-1 col"></div>
    <div class="col-2 col">
      <span class="footer-text">icandoathing copyright 2022</span>
    </div>
    <div class="col-3 col">
      <p class="footer-text">
        For any questions or comments, please email
        <a href="mailto:admin@icandoathing.com" class="link"
          >admin@icandoathing.com</a
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss" scoped>
.main-footer {
  position: relative;
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
  width: 100%;
  height: 10rem;
  min-height: 10rem;
  background-color: $grey-300;
}
.col {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-self: center;

  height: 90%;
}
.footer-text {
  font-weight: 300;
  align-self: flex-end;
  margin: $item-margin;
  justify-self: center;
  text-align: center;
}
.col-2 {
  border-left: solid $grey-400 1px;
  border-right: solid $grey-400 1px;
}
@media screen and (max-width: $switch-width) {
  .col-1 {
    display: none;
  }
  .main-footer {
    grid-template-columns: 100%;
  }
}
</style>
