export const textStylingMixin = {
  methods: {
    getDateText(date) {
      let dateObj = new Date(date);
      let todaysDate = new Date();
      let finalString = "";
      if (todaysDate.getFullYear() == dateObj.getFullYear()) {
        finalString += "this ";
      } else {
        finalString += "in " + dateObj.getFullYear() + ", ";
      }
      finalString += dateObj.toLocaleString("default", { month: "long" });
      let pr = new Intl.PluralRules("en-US", { type: "ordinal" });
      const suffixes = new Map([
        ["one", "st"],
        ["two", "nd"],
        ["few", "rd"],
        ["other", "th"],
      ]);
      const formatOrdinals = (n) => {
        const rule = pr.select(n);
        const suffix = suffixes.get(rule);
        return `${n}${suffix}`;
      };
      finalString += " " + formatOrdinals(dateObj.getDate());
      finalString += " at " + dateObj.toLocaleTimeString();
      return finalString;
    },
    getLikesText(num) {
      if (num < 1000) {
        return num;
      } else if (num < 1000000) {
        return (num / 1000).toPrecision(3) + "K";
      } else {
        return (num / 1000000).toPrecision(3) + "M";
      }
    },
  },
};
