<template>
  <svg class="progress-ring" :height="radius * 2" :width="radius * 2">
    <circle
      class="progress-ring__circle"
      id="loading-circle"
      ref="circle"
      :stroke-width="borderWidth"
      stroke="black"
      fill="transparent"
      :r="trueRadius"
      :cx="radius"
      :cy="radius" />
  </svg>
</template>

<script>
export default {
  name: "LoadingCircle",
  data() {
    return {};
  },
  props: {
    radius: {
      type: Number,
      default: 50,
    },
    borderWidth: {
      type: Number,
      default: 7,
    },
    numOfColours: {
      type: Number,
      default: 15,
    },
  },
  computed: {
    trueRadius() {
      return this.$props.radius - this.$props.borderWidth * 2;
    },
    circumference() {
      return this.$props.radius * Math.PI * 2;
    },
  },
  mounted() {
    let circle = this.$refs.circle;
    circle.style.strokeDasharray =
      this.circumference + " " + this.circumference;
    let val = 0;
    let rainbow = Array(this.numOfColours)
      .fill("")
      .map(
        (x, i) => "hsl(" + i * ((360 / this.numOfColours) | 0) + ",50%,50%)"
      );
    let count = (Math.random() * this.numOfColours) | 0; //loading circle will start with a random colour of the rainbow
    circle.style.stroke = rainbow[count];
    setTimeout(() => {
      circle.style.strokeDashoffset = this.circumference;
    }, 100);
    setInterval(() => {
      if (count > this.numOfColours - 1) count = 0;
      count++;
      val = val == 0 ? this.circumference * 2.22 : 0;
      circle.style.strokeDashoffset = val + "";
      circle.style.stroke = rainbow[count];
    }, 1990);
  },
};
</script>

<style lang="scss" scoped>
.progress-ring__circle {
  transition: stroke-dashoffset 2s cubic-bezier(0.215, 0.61, 0.355, 0.2),
    stroke 2s ease;
  animation: rotate 4s ease infinite;
  transform-origin: 50% 50%;
}
@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
