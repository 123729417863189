<template>
  <div class="gallery-card">
    <img
      v-if="hasImage"
      :src="mainImageSrc"
      alt=""
      class="main-image"
      @click.self="goToPostPage()" />
    <div class="title-container blur-item" v-if="hasImage"></div>
    <div
      class="title-container"
      :class="[hasImage ? '' : 'on-top']"
      @click.self="goToPostPage()">
      <h4 class="title text">
        {{ postData.title }}
      </h4>
      <div class="description-container" v-if="!hasImage">
        <p class="description-text">{{ postBody }}</p>
      </div>
      <div class="tag-box container row">
        <tag-pill
          class="clickable"
          v-for="tag in postData.tags"
          :key="tag"
          :name="tag.name"
          :color="tag.color"
          :fontSize="0.7"
          @click.stop="$router.push('/tag/' + tag.name)"></tag-pill>
      </div>
      <div class="bottom-info-box">
        <div
          class="author-box info-box"
          @click="$router.push('/user/' + postData.author.username)">
          <account-icon class="small-icon"></account-icon>
          <p class="author-name info-box-text">{{ authorName }}</p>
        </div>
        <div class="likes-box info-box" @click="onHeartClick">
          <p class="likes-text info-box-text">
            <span class="likes-num number"> {{ styledLikes }} </span>
          </p>
          <heart-icon
            class="small-icon heart-icon"
            :class="{ liked: liked, 'liked-animate': animate }"></heart-icon>
        </div>
      </div>
    </div>
    <div class="updates-and-status-container">
      <div
        class="status-box"
        :class="[statusColor, hasUpdates ? 'no-border-radius' : '']">
        <span class="status-text">{{ status }}</span>
      </div>
      <div
        v-if="hasUpdates"
        class="update-box primary-300 white-text"
        :class="[isFollowing ? 'no-border-radius' : '']">
        <span class="status-text"
          >+ {{ postData.has_updates }} update{{
            postData.has_updates > 1 ? "s" : ""
          }}</span
        >
      </div>
      <div v-if="isFollowing" class="update-box green white-text">
        <span
          class="status-text"
          data-tooltip="you are following this post, and it will appear higher when it has an update."
          >following</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import TagPill from "../basic/TagPill.vue";
import AccountIcon from "vue-material-design-icons/Account.vue";
import HeartIcon from "vue-material-design-icons/Heart.vue";
import { socialsMixin } from "@/mixins/socialsMixin";
import { imageMixin } from "@/mixins/imageMixin.js";
export default {
  name: "PostGalleryView",
  props: {
    postData: {
      type: Object,
      default: undefined,
    },
  },
  components: {
    TagPill,
    AccountIcon,
    HeartIcon,
  },
  mixins: [socialsMixin, imageMixin],
  data() {
    return {
      numOfLikes: 0,
      liked: false,
      mainImageSrc: this.loadingImageSrc,
      animate: false,
    };
  },
  computed: {
    hasImage() {
      return (
        this.postData.album !== null &&
        this.postData.album.sections.length !== 0 &&
        this.postData.album.sections.filter(
          (x) => x.image !== null || x.external_url !== null
        ).length
      );
    },
    authorName() {
      return this.postData.author.alias || this.postData.author.username;
    },
    styledLikes() {
      return this.numOfLikes > 999
        ? (this.numOfLikes / 1000).toPrecision(2) + "K"
        : this.numOfLikes;
    },
    postBody() {
      return this.postData.description;
    },
    status() {
      switch (this.postData.status) {
        case "c":
          return "completed";
        case "p":
          return "planning";
        case "s":
          return "stalled";
        case "i":
          return "in progress";
        default:
          return "new thing";
      }
    },
    hasUpdates() {
      //not just update, if this is an update and it isnt complete or stalled
      return this.postData.has_updates > 0;
    },
    statusColor() {
      switch (this.postData.status) {
        case "c":
          return "yellow white-text";
        case "p":
          return "purple white-text";
        case "s":
          return "warning-600 white-text";
        case "i":
          return "info-500 white-text";
        default:
          return "grey-500 white-text";
      }
    },
    isFollowing() {
      if (!this.$store.state.isAuthenticated) {
        return false;
      }
      return this.$store.state.userData.following_posts
        .map((x) => x.id)
        .includes(this.postData.id);
    },
  },
  mounted() {
    this.numOfLikes = this.postData.likes;
    this.mainImageSrc = this.loadingImageSrc;
    if (this.$store.state.isAuthenticated) {
      if (this.$store.state.userData.liked_posts.includes(this.postData.id)) {
        this.liked = true;
      }
    }
    let index = this.postData.main_image;
    if (
      !this.postData.album.sections[index] ||
      (!this.postData.album.sections[index].image &&
        !this.postData.album.sections[index].external_url)
    ) {
      index = 0;
    }
    if (this.postData.album.sections[index].image) {
      this.mainImageSrc = this.postData.album.sections[index].image;
    } else if (this.postData.album.sections[index].external_url) {
      this.getSrc(this.postData.album.sections[index].external_url).then(
        (url) => {
          this.mainImageSrc = url;
        }
      );
    }
  },
  methods: {
    goToPostPage() {
      this.$store.commit("setViewPost", this.postData);
      this.$router.push(
        "/thing/" + this.postData.id + "/" + this.postData.slug
      );
    },
    onHeartClick() {
      if (this.$store.state.isAuthenticated) {
        if (this.liked) {
          if (this.postData.id !== "") this.dislike(this.postData.id);
          this.liked = false;
          this.numOfLikes--;
        } else {
          if (this.postData.id !== "") this.like(this.postData.id);
          this.liked = true;
          this.numOfLikes++;
          this.animate = true;
          setTimeout(() => {
            this.animate = false;
          }, 1000);
        }
      } else {
        this.$notify({
          title: "Please log in!",
          body: "Please log in to like this thing",
          icon: "",
          type: "success",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.gallery-card {
  background-color: $grey-300;
  height: 26rem;
  width: 17rem;
  overflow: hidden;
  border-radius: $border-radius;
  position: relative;
}
.main-image {
  object-fit: cover;
  position: absolute;
  height: 100%;
}
.title-container {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: rgba($primary-700, 0.4);
  height: fit-content;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  backdrop-filter: blur(4px);
  &.on-top {
    position: relative;
    bottom: auto;
    height: 100%;
    align-items: space-between;
    justify-content: space-between;
    padding-top: 2rem;
  }
  &:hover {
    cursor: pointer;
    .title {
      color: $primary-300;
    }
  }
}

.title {
  font-family: $title-font;
  color: $white;
  font-size: 1.5rem;
  margin: 0.5rem;
  transition: color 0.3s;
}
.bottom-info-box {
  display: flex;
  flex-direction: row;
  height: 1.5rem;
  justify-content: space-between;
  width: 100%;
}
.info-box {
  background-color: $grey-700;
}
.author-box {
  border-radius: 0 $border-radius 0 $border-radius;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.author-name {
  font-weight: 300;
  font-size: 0.9rem;
  margin: 0 0.3rem;
}
.small-icon {
  margin: 0.1rem;
  margin-top: -0.2rem;
  margin-left: 0.2rem;
}
.likes-box {
  border-radius: $border-radius 0 $border-radius 0;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.likes-text {
  margin: 0 0 0 0.4rem;
  font-weight: 300;
  font-size: 0.9rem;
  margin-left: 0.6rem;
  margin-right: 0.1rem;
}
.heart-icon {
  margin-top: 0;
  font-size: 0.9rem;
  &.liked {
    color: $pink;
  }
  &:hover:not(.liked) {
    color: $primary-200;
  }
}
.tag-box {
  height: 1.2rem;
  column-gap: 0.2rem;
  flex-wrap: wrap;
  overflow: hidden;
  row-gap: 3rem;
}
.description-container {
  padding: 1rem;
  flex-grow: 1;
}
.description-text {
  color: $white;
}
.status-box {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 0.2rem;
  font-size: 0.9rem;
  border-radius: 0 $border-radius 0 $border-radius;
  &.no-border-radius {
    border-radius: 0;
  }
}

.update-box {
  display: flex;
  text-align: center;
  justify-content: center;
  padding: 0.2rem;
  align-items: center;
  border-radius: 0 0 0 $border-radius;
  font-size: 0.9rem;
  &.no-border-radius {
    border-radius: 0;
  }
}
.update-box > * {
  margin: 0 0.4rem;
}
.updates-and-status-container {
  top: 0;
  right: 0;
  position: absolute;
  display: block;
}
@media screen and (max-width: $switch-width) {
  .gallery-card {
    background-color: $grey-300;
    height: 60vh;
    width: 100%;
    margin: 0 1rem;
    overflow: hidden;
    border-radius: $border-radius;
    position: relative;
  }
}
</style>
