import Notification from "@/components/notifications/NotificationsContainer.vue";
export default {
  // eslint-disable-next-line no-unused-vars
  install: (app, options) => {
    app.component("notifications", Notification);
    const vm = app.component("notifications");
    app.config.globalProperties.$notify = (data) => {
      data.id = Math.random().toString(36).slice(2); //random alphanumeric id
      vm.methods.addNewNotification(data);
    };
  },
};
