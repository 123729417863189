export const imageMixin = {
  data() {
    return {
      loadingImageSrc: require("@/assets/loading.jpg"),
    };
  },
  methods: {
    matchYoutube(string) {
      return string.match(
        /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-_]*)(&(amp;)?\w\?=]*)?/
      );
    },
    matchImage(string) {
      return string.match(/[.](gif|jpg|jpeg|tiff|png|webp)(?=\?.+|$)/i);
    },
    matchVideo(string) {
      return string.match(
        /(.*?)\.(swf|avi|flv|mpg|rm|mov|wav|asf|3gp|mkv|rmvb|mp4)$/i
      );
    },
    getYoutubeURL(code) {
      return `https://www.youtube.com/embed/${code}`;
    },
    getYoutubeThumb(code) {
      return `https://i.ytimg.com/vi/${code}/maxresdefault.jpg`;
    },
    getThumbBlobURLFromVideoURL(video_url) {
      return new Promise((resolve) => {
        let vid = document.createElement("video");
        vid.setAttribute("preload", "metadata");
        vid.addEventListener("loadedmetadata", () => {
          setTimeout(() => {
            vid.currentTime = 0.5;
          }, 100);
          vid.addEventListener("seeked", () => {
            try {
              let canv = document.createElement("canvas");
              let ctx = canv.getContext("2d");
              canv.height = vid.videoHeight;
              canv.width = vid.videoWidth;
              ctx.drawImage(vid, 0, 0, vid.videoWidth, vid.videoHeight);
              resolve(ctx.canvas.toDataURL());
              vid.remove();
              canv.remove();
            } catch (e) {
              resolve("error");
            }
          });
        });
        vid.crossOrigin = "";
        vid.src = video_url;
      });
    },
    async getSrc(image) {
      //used by submit/update/and edit forms to get src for the previews at the bottom
      return new Promise((resolve, reject) => {
        if (typeof image == "string") {
          if (image.startsWith("url:")) {
            image = image.slice(4);
          }
          if (this.matchImage(image)) {
            return resolve(image);
          } else if (this.matchVideo(image)) {
            this.getThumbBlobURLFromVideoURL(image).then((url) => {
              return resolve(url);
            });
            return;
          } else if (this.matchYoutube(image)) {
            let code = this.matchYoutube(image)[1];
            return resolve(this.getYoutubeThumb(code));
          }
          console.log(image);
          return resolve(URL.createObjectURL(image));
        }
        reject("invalid url");
      });
    },
  },
};
