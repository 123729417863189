<template>
  <div
    class="post-list-view"
    @resize="onResize()"
    ref="mainPost"
    :class="{ 'no-image': !hasImage }">
    <div class="image-container" v-if="hasImage" @click="goToPostPage()">
      <img :src="mainImageSrc || loadingImageSrc" alt="" class="main-image" />
    </div>
    <div class="post-info">
      <div class="post-upper-area">
        <div class="post-text" @click="goToPostPage()">
          <h4 class="post-title">{{ postData.title }}</h4>
          <p class="post-body" ref="description">{{ description }}</p>
        </div>
        <div class="updates-and-status-container">
          <div
            class="status-box"
            :class="[statusColor, hasUpdates ? 'no-border-radius' : '']">
            <span class="status-text">{{ status }}</span>
          </div>
          <div
            v-if="hasUpdates"
            class="update-box primary-300 white-text"
            :class="[isFollowing ? 'no-border-radius' : '']">
            <span class="status-text"
              >+ {{ postData.has_updates }} update{{
                postData.has_updates > 1 ? "s" : ""
              }}</span
            >
          </div>
          <div v-if="isFollowing" class="following-box green white-text">
            <span
              class="status-text"
              data-tooltip="you are following this post, and it will appear higher when it has an update."
              >following</span
            >
          </div>
        </div>
      </div>
      <div class="post-lower-area">
        <div
          class="author-box info-box"
          @click="$router.push('/user/' + postData.author.username)">
          <account-icon class="small-icon"></account-icon>
          <p class="author-name info-box-text">{{ authorName }}</p>
        </div>
        <div class="tag-box container row">
          <tag-pill
            class="clickable"
            v-for="tag in postData.tags.slice(0, 6)"
            :key="tag"
            :name="tag.name"
            :color="tag.color"
            :fontSize="tagFontSize"
            @click.stop="$router.push('/tag/' + tag.name)"></tag-pill>
        </div>

        <div class="likes-box info-box" @click="onHeartClick">
          <p class="likes-text info-box-text">
            <span class="likes-num number"> {{ styledLikes }} </span>
            &nbsp;{{ numOfLikes === 1 ? "like" : "likes" }}
          </p>
          <heart-icon
            class="small-icon heart-icon"
            :class="{ liked: liked, 'liked-animate': animate }"></heart-icon>
        </div>
      </div>
    </div>
    <div class="bottom-socials">
      <div
        class="mobile-author-box"
        @click="$router.push('/user/' + postData.author.username)">
        <account-icon class="mobile-icon"></account-icon>
        <p class="mobile-author-text">{{ authorName }}</p>
      </div>
      <div class="mobile-likes-box" @click="onHeartClick">
        <p class="mobile-likes-text">{{ styledLikes }}</p>
        <heart-icon
          class="mobile-icon"
          :class="{ liked: liked, 'liked-animate': animate }"></heart-icon>
      </div>
    </div>
  </div>
</template>

<script>
import TagPill from "../basic/TagPill.vue";
import AccountIcon from "vue-material-design-icons/Account.vue";
import HeartIcon from "vue-material-design-icons/Heart.vue";
import { socialsMixin } from "@/mixins/socialsMixin";
import { imageMixin } from "@/mixins/imageMixin.js";
export default {
  name: "PostListView",
  components: {
    TagPill,
    AccountIcon,
    HeartIcon,
  },
  props: {
    postData: Object,
  },
  mixins: [socialsMixin, imageMixin],
  data() {
    return {
      text: "",
      tagFontSize: 1.1,
      numOfLikes: 0,
      numOfFollowers: 0,
      liked: false,
      mainImageSrc: undefined,
      animate: false,
    };
  },
  computed: {
    description() {
      return this.text == "" ? this.postData.description : this.text;
    },
    authorName() {
      return this.postData.author.alias || this.postData.author.username;
    },
    status() {
      switch (this.postData.status) {
        case "c":
          return "completed";
        case "p":
          return "planning";
        case "s":
          return "stalled";
        case "i":
          return "in progress";
        default:
          return "new thing";
      }
    },
    statusColor() {
      switch (this.postData.status) {
        case "c":
          return "yellow white-text";
        case "p":
          return "purple white-text";
        case "s":
          return "warning-600 white-text";
        case "i":
          return "info-500 white-text";
        default:
          return "grey-500 white-text";
      }
    },
    hasUpdates() {
      return this.postData.has_updates > 0;
    },
    hasImage() {
      return (
        this.postData.album !== null &&
        this.postData.album.sections.length !== 0 &&
        this.postData.album.sections.filter(
          (x) => x.image !== null || x.external_url !== null
        ).length
      );
    },

    styledLikes() {
      return this.numOfLikes > 999
        ? (this.numOfLikes / 1000).toPrecision(2) + "K"
        : this.numOfLikes;
    },
    styledFollowers() {
      return this.numOfFollowers > 999
        ? (this.numOfFollowers / 1000).toPrecision(2) + "K"
        : this.numOfFollowers;
    },
    isFollowing() {
      if (!this.$store.state.isAuthenticated) {
        return false;
      }
      return this.$store.state.userData.following_posts
        .map((x) => x.id)
        .includes(this.postData.id);
    },
  },
  mounted() {
    requestAnimationFrame(() => {
      this.onResize();
    });

    this.mainImageSrc = this.loadingImageSrc;
    this.numOfFollowers = this.postData.followers;
    this.numOfLikes = this.postData.likes;
    if (this.$store.state.isAuthenticated) {
      if (this.$store.state.userData.liked_posts.includes(this.postData.id)) {
        this.liked = true;
      }
    }
    let index = this.postData.main_image;
    if (
      !this.postData.album.sections[index] ||
      (!this.postData.album.sections[index].image &&
        !this.postData.album.sections[index].external_url)
    ) {
      index = 0;
    }
    if (this.postData.album.sections[index].thumbnail) {
      this.mainImageSrc = this.postData.album.sections[index].thumbnail;
    } else if (this.postData.album.sections[index].external_url) {
      this.getSrc(this.postData.album.sections[index].external_url).then(
        (url) => {
          this.mainImageSrc = url;
        }
      );
    }

    //TODO add dynamic likes number changing
  },
  methods: {
    goToPostPage() {
      this.$store.commit("setViewPost", this.postData);
      this.$router.push(
        "/thing/" + this.postData.id + "/" + this.postData.slug
      );
    },
    onResize() {
      let width = this.$refs.description.clientWidth || this.$el.clientWidth;
      this.tagFontSize = Math.min(width / 400, 1);
      // let lettersToFit = ((this.$refs.description.clientWidth / 8) | 0) * 3;
      this.text = this.postData.description;
    },
    onHeartClick() {
      if (this.$store.state.isAuthenticated) {
        if (this.liked) {
          if (this.postData.id !== "") this.dislike(this.postData.id);
          this.liked = false;
          this.numOfLikes--;
        } else {
          if (this.postData.id !== "") this.like(this.postData.id);
          this.liked = true;
          this.numOfLikes++;
          this.animate = true;
          setTimeout(() => {
            this.animate = false;
          }, 1000);
        }
      } else {
        this.$notify({
          title: "Please log in!",
          body: "Please log in to like this thing",
          icon: "",
          type: "info",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  display: flex;
}
.completed-text {
  color: $warning-700;
}
.post-list-view {
  background-color: $grey-200;
  margin: $item-margin;
  max-height: 9rem;
  border-radius: $border-radius;
  overflow: hidden;
  width: auto;
  &:hover {
    .post-title {
      color: $primary-500;
      cursor: pointer;
    }
    .post-body {
      color: $primary-500;
      cursor: pointer;
    }
  }
}
.main-image {
  object-fit: cover;
}
.image-container {
  border-radius: $border-radius;
  border: $grey-800 solid 1px;
  max-width: calc(min(16rem, 16vw));
  min-width: calc(min(16rem, 16vw));
  overflow: hidden;
}
.post-info {
  width: calc(min(50vw, 400px));
  flex-direction: column;
  flex-grow: 1;
}
.post-upper-area {
  flex-direction: row;
  height: 7rem;
}
.post-text {
  flex-grow: 1;
  flex-direction: column;
}
.status-box {
  height: 2rem;
  padding: 0 0.5rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 0 $border-radius;
  &.no-border-radius {
    border-radius: 0;
  }
}
.status-text {
  white-space: nowrap;
}
.update-box {
  height: 2rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 0 $border-radius;
  &.no-border-radius {
    border-radius: 0;
  }
}
.update-box > * {
  margin: 0 0.4rem;
}
.following-box {
  height: 2rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 0 $border-radius;
}
.following-box > * {
  margin: 0 0.4rem;
}
.post-lower-area {
  flex-direction: row;
  height: 2rem;
  justify-content: flex-end;
  align-items: flex-end;
}
.post-title {
  font-family: "Bebas Neue", sans-serif;
  text-align: left;
  padding-left: 1rem;
  font-size: 1.7rem;
  margin: 0;
  margin-top: 0.6rem;
  color: $primary-600;
  transition: color 0.2s;
}
.post-body {
  margin: 0;
  padding-left: 1rem;
  line-height: 1.2;
  text-align: left;
  color: $primary-600;
  font-weight: 400;
  grid-area: desc;
  max-width: inherit;
  transition: color 0.2s;
  height: 7ch;
  overflow: hidden;
}
.info-box {
  background-color: $grey-500;
  border-radius: $border-radius $border-radius 0 0;
  padding: 0 0.4rem;
  justify-content: center;
  align-items: center;
  color: $white;
  margin: 0 0.3rem;
  height: 2rem;
}
.author-box {
  width: fit-content;
  max-width: 13rem;
}
.author-name {
  white-space: nowrap;
  padding: 0 0.2rem;
}
.followers-box {
  &:hover {
    .plus-icon {
      color: $primary-200;
    }
  }
}
.followers-box .small-icon {
  font-size: 1.1rem;
  margin-top: -1px;
}
.number {
  padding-left: 0.5rem;
  font-weight: 900;
}
.info-box-text {
  font-weight: 300;
  user-select: none;
}
.likes-box {
  cursor: pointer;
  &:hover {
    .heart-icon:not(.liked) {
      color: $primary-200;
    }
  }
}
.tag-box {
  padding-left: 1rem;
  justify-content: center;
  column-gap: 0.3rem;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 1rem;
}
.small-icon {
  font-size: 1.2rem;
  margin: 0.2rem;
  padding-left: 0.3rem;
}
.updates-and-status-container {
  display: block;
  user-select: none;
}
.bottom-socials {
  display: none;
}
.mobile-icon {
  cursor: pointer;
  margin: 0.3rem;
  color: $grey-500;
  margin-top: 0.1rem;
}
.mobile-author-text {
  cursor: pointer;
  font-weight: 200;
  font-size: 0.9rem;
  margin: auto;
}
.mobile-likes-text {
  cursor: pointer;
  font-weight: 200;
}
@media screen and (max-width: $switch-width) {
  .post-list-view {
    display: grid;
    grid-template-columns: 120px auto;
    grid-template-areas: "pic data" "socials socials";
    grid-template-rows: 7rem 2rem;
    max-height: fit-content;
    justify-items: stretch;
    align-items: center;
    height: 9rem;
    padding: 0rem;
    &.no-image {
      grid-template-columns: auto;
      grid-template-areas: "data" "socials";
    }
  }
  .main-image {
    min-height: 3rem;
    min-width: none;
  }
  .image-container {
    min-width: auto;
    max-width: none;
    grid-area: pic;
    flex-grow: 1;
    width: 120px;
    height: 100%;
  }
  .post-info {
    grid-area: data;
    width: 100%;

    height: 100%;
    justify-content: space-between;
  }
  .bottom-socials {
    grid-area: socials;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
  }
  .post-upper-area {
    height: auto;
    justify-content: space-between;
    align-items: space-between;
  }
  .post-title {
    padding-left: $item-margin;
    flex-grow: 1;
    font-size: 1.05rem;
    margin: 0;
    margin-top: $item-margin;
  }
  .post-body {
    padding-left: $item-margin;
    font-size: 0.8rem;
  }
  .info-box-text {
    font-size: 0.7rem;
  }
  .small-icon {
    font-size: 0.8rem;
  }
  .info-box {
    padding: 0.1rem;
    height: 1.3rem;
  }
  .followers-box {
    display: none;
  }
  .likes-box {
    display: none;
  }
  .status-box {
    font-size: 0.7rem;
    padding: 0.2rem;
    height: 1.1rem;
  }
  .update-box,
  .following-box {
    font-size: 0.6rem;
    padding: 0.2rem;
    height: 1.1rem;
    word-wrap: none;
  }
  .status-text {
    margin: 0;
    white-space: nowrap;
  }
  .tag-box {
    padding: 0;
    flex-wrap: wrap;
    row-gap: 0.1rem;
  }
  .author-box {
    display: none;
  }
}
</style>
