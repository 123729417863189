<template>
  <div class="notifications-container">
    <Notification
      v-for="notification in notifications"
      :notificationData="notification"
      :key="notification.id"></Notification>
  </div>
</template>

<script>
import Notification from "./Notification.vue";
import { mapState } from "vuex";
import store from "@/store";
export default {
  name: "NotificationsContainer",
  components: {
    Notification,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      notifications: (state) => state.notifications.notifications,
    }),
  },
  methods: {
    addNewNotification(data) {
      store.commit("notifications/add", data);
    },
  },
};
</script>

<style lang="scss" scoped>
.notifications-container {
  z-index: 100000000000;
  position: fixed;
  top: 0;
  right: 0;
}
</style>
