import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import "vue-material-design-icons/styles.css";
import Notify from "./plugins/notifications.js";
import "./registerServiceWorker";
import { VueShowdownPlugin } from "vue-showdown";

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;
createApp(App)
  .use(store)
  .use(router)
  .use(Notify)
  .use(VueShowdownPlugin, {
    flavor: "github",
  })
  .mount("#app");
