<template>
  <transition name="from-side">
    <div v-if="show" class="loading-wrapper">
      <div class="loading-icon">
        <LoadingCircle :radius="35"></LoadingCircle>
      </div>
    </div>
  </transition>
</template>

<script>
import LoadingCircle from "@/components/basic/LoadingCircle";
export default {
  name: "LoadingIcon",
  components: {
    LoadingCircle,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-wrapper {
  z-index: 999999999;
  position: fixed;
  top: 30px;
  background-color: $white;
  height: 80px;
  width: 150px;
  box-shadow: rgba($color: #979797, $alpha: 0.1) 5px 0px 7px 5px,
    rgba($color: #646464, $alpha: 0.5) 2px 0px 2px 1px;
  border-radius: 0 40px 40px 0;
  padding: 5px;
  display: flex;
  justify-content: right;
}
.from-side-enter-active {
  transition: all 0.2s ease-in;
  animation: fromleft 0.2s;
}
.from-side-leave-to {
  animation: fromleft 0.2s reverse;
}
.from-side-leave-active {
  transition: all 0.2s ease-out;
}
@keyframes fromleft {
  0% {
    left: -200px;
  }
  100% {
    left: -10px;
  }
}
</style>
