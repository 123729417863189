import { createStore } from "vuex";
import axios from "axios";
import notifications from "./modules/notifications";
export default createStore({
  state: {
    token: "",
    isAuthenticated: false,
    userData: {},
    isLoading: false,
    _isLoadingCount: 0, //will act like boolean
    _loadingTimeout: 0, //used for the setTimeout
    nextViewPost: undefined,
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
      localStorage.setItem("token", token);
      axios.defaults.headers.common["Authorization"] = "token " + token;
    },
    removeToken(state) {
      state.token = "";
      localStorage.removeItem("token");
      axios.defaults.headers.common["Authorization"] = "";
    },
    setAuthentication(state, boolean) {
      state.isAuthenticated = boolean;
    },
    setUserData(state, userData) {
      state.userData = userData;
    },
    removeUserData(state) {
      state.userData = {};
      localStorage.removeItem("userData");
    },
    setLoading(state, boolean) {
      if (boolean) {
        //if multiple things are loading, we dont want the fisrt thing to stop loading to set this to false, as then the loadingbar will disappear
        state._isLoadingCount += 1; // so we increment a counter for each thing loading, as its expected that all components that set it to true will eventually set it to false
      } else {
        state._isLoadingCount -= 1; // so when all the components stop loading, the counter will be set to 0 and isLoading is false and the loading bar can go away
        if (state._isLoadingCount < 0) state._isLoadingCount = 0;
      }
      let toSet = state._isLoadingCount > 0;
      if (toSet) {
        setTimeout(() => {
          state.isLoading = state._isLoadingCount > 0; //loading will only start if 1 second passes, no need to show loading if its really fast
          state._loadingTimeout = setTimeout(() => {
            //if were loading more than 10 seconds, there might be an issue. Just turn it off
            state.isLoading = false;
            state._isLoadingCount = 0;
          }, 10000);
        }, 1000);
      } else {
        state.isLoading = toSet; //but there shouldnt be a delay when turning off the loading
        clearTimeout(state._loadingTimeout); //and if we turn it off faster than 10 seconds, then get rid of the timout
      }
    },
    setViewPost(state, data) {
      state.nextViewPost = data;
    },
    clearViewPost(state) {
      state.nextViewPost = undefined;
    },
  },
  actions: {
    async initialize(context) {
      let resolved = false;
      return new Promise((resolve) => {
        if (localStorage.getItem("token")) {
          context.commit("setToken", localStorage.getItem("token"));
          context.commit("setAuthentication", true);
          if (localStorage.getItem("useBasicCookies") === "true") {
            if (localStorage.getItem("userData")) {
              context.commit(
                "setUserData",
                JSON.parse(localStorage.getItem("userData"))
              );
              resolved = true;
              resolve();
            }
          }
          axios
            .get("/api/v1/users/me/")
            .then((res) => {
              context.commit("setUserData", res.data);
              if (localStorage.getItem("useBasicCookies") === "true") {
                localStorage.setItem("userData", JSON.stringify(res.data));
              }
              context.commit("setAuthentication", true);
              if (!resolved) resolve();
            })
            .catch(() => {
              context.commit("removeToken");
              context.commit("removeUserData");
              context.commit("setAuthentication", false);
              if (!resolved) resolve();
            });
        } else {
          context.commit("removeToken");
          context.commit("removeUserData");
          context.commit("setAuthentication", false);
          if (!resolved) resolve();
        }
      });
    },
    ping(context) {
      if (context.state.isAuthenticated) {
        axios.post("/api/v1/users/ping/").then((res) => {
          context.commit("setUserData", res.data);
        });
      } else {
        console.log("not auth");
      }
    },
  },
  modules: {
    notifications,
  },
});
