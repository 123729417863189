<template>
  <div @click="$router.push('/user')" class="compact-avatar">
    <div class="avatar-box">
      <img v-if="hasImage" :src="$store.state.userData.thumbnail" alt="" />
      <img
        class="small-image default-profile"
        v-else
        src="../assets/svg/temp-default-profile.svg"
        alt="" />
    </div>
    <div class="name-box">
      <h4 class="alias">{{ name }}</h4>
      <div class="likes">
        <span class="likes-text"> {{ likes }}</span>
        <img src="../assets/svg/temp-heart.svg" alt="" class="small-icon" />
      </div>
    </div>
  </div>
</template>

<script>
import { textStylingMixin } from "@/mixins/textStylingMixin";
export default {
  name: "CompactAvatar",
  mixins: [textStylingMixin],
  computed: {
    likes() {
      return this.getLikesText(this.$store.state.userData.likes);
    },
    name() {
      return !this.$store.state.userData.alias ||
        this.$store.state.userData.alias === ""
        ? this.$store.state.userData.username
        : this.$store.state.userData.alias;
    },
    hasImage() {
      return (
        this.$store.state.userData.thumbnail !== "" &&
        this.$store.state.userData.thumbnail !== null
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.compact-avatar {
  display: grid;
  grid-template-columns: 64px auto;
  border-radius: 0.5rem;
  background-color: $grey-300;
  margin: 1rem $item-margin;
  max-height: 64px;
  cursor: pointer;
}
.name-box {
  display: flex;
  flex-direction: column;
  max-height: 64px;
  align-items: center;
}
.avatar-box {
  width: 64px;
  height: 64px;
  border-radius: $border-radius;
  overflow: hidden;
  background-color: $white;
}
.alias {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-grow: 1;
  margin: 0;
  padding: 0;
  line-height: 1;
  text-align: center;
  overflow-wrap: anywhere;
}
.likes {
  background-color: $grey-500;
  border-radius: 0.5rem 0 0.5rem 0;
  margin-left: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: right;
  column-gap: 0.3rem;
  padding: 0 0.3rem;
  height: 1.1rem;
  width: fit-content;
  justify-self: end;
  align-self: end;
}
.likes-text {
  color: $white;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  text-align: left;
}
.small-icon {
  width: 0.7rem;
}
.default-profile {
  padding: $item-margin;
}
@media screen and (max-width: $switch-width) {
  .compact-avatar {
    margin: $item-margin;
  }
}
</style>
